<template>
  <div>
    <div class="flex mb-4 justify-between">
      <h1 class="font-medium text-3xl">{{ $t("spares") }}</h1>
    </div>
    <div class="flex">
      <div class="flex-1 overflow-hidden">
        <el-table :data="spare" border v-loading="loading" style="width: 100%" class="rounded-lg shadow-md">
          <el-table-column prop="id" label="ID" width="60"> </el-table-column>
          <el-table-column prop="name" :label="$t('name')" min-width="180"> </el-table-column>
          <el-table-column prop="serial_number" :label="$t('serial_number')" min-width="180"> </el-table-column>
          <el-table-column :label="$t('status')" min-width="180">
            <template slot-scope="scope">
              <el-tag type="danger" size="small" v-if="scope.row.status == 11">
                {{ $t("ASKED") }}
              </el-tag>
              <el-tag type="warning" size="small" v-if="scope.row.status == 22">
                {{ $t("PREPARING") }}
              </el-tag>
              <el-tag type="success" size="small" v-if="scope.row.status == 33">
                {{ $t("COME") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="$t('actions')" width="240" v-if="role == 100">
            <template slot-scope="scope">
              <div class="flex justify-between">
                <el-button icon="el-icon-tickets" size="medium" @click="openLog(scope.row.id)"> </el-button>
                <el-button @click="editStatus(scope.row.id, 22)" class="mx-1" type="warning" size="mini" v-if="scope.row.status == 11">
                  {{ $t("PREPARING") }}
                </el-button>
                <el-button @click="editStatus(scope.row.id, 33)" class="mx-1" type="success" size="mini" v-if="scope.row.status == 22">
                  {{ $t("COME") }}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="mt-3 flex justify-end">
          <el-pagination background layout="prev, pager, next" hide-on-single-page @prev-click="Prev" @next-click="Next" @current-change="handleCurrentChange" :total="totalCount"> </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('logs')" class="log_dialog" :visible.sync="logDialog">
      <el-table :data="spare_logs" border v-loading="log_loading">
        <el-table-column
          property="user"
          :label="$t('user')"
          min-width="150"
        ></el-table-column>
        <el-table-column
          property="text"
          :label="$t('text')"
          min-width="300"
        ></el-table-column>
        <el-table-column
          property="created_time"
          :label="$t('created_time')"
          width="180"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Spares",
  data() {
    return {
      logDialog: false,
      log_loading: false,
      spare_logs: [],
      id: "",
      spare: [],
      totalCount: 0,
      count: 1,
      loading: false,
      role: localStorage.role,
    };
  },
  created() {
    this.getSpares();
  },
  methods: {
    openLog(id) {
      this.logDialog = true;
      this.log_loading = true;
      axios
        .get(`spares/${id}/logs/`)
        .then((response) => {
          this.log_loading = false;
          this.spare_logs = response.data.map((data) => {
            return {
              ...data,
              created_time: data.created_time
                .split(".")
                .shift()
                .replace("T", " "),
            };
          });
        })
        .catch(() => {
          this.log_loading = false;
        });
    },
    async getSpares() {
      this.loading = true;
      await axios
        .get(`spares/${this.count === 1 ? "" : "?page=" + this.count}`)
        .then((response) => {
          this.totalCount = response.data.count;
          this.spare = response.data.results;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Prev() {
      this.count--;
      this.getSpares();
    },
    handleCurrentChange(val) {
      this.count = val;
      this.getSpares();
    },
    Next() {
      this.count++;
      this.getSpares();
    },
    editStatus(id, statusId) {
      this.loading = true;
      axios
        .patch(`spares/${id}/`, { status: statusId })
        .then(() => {
          this.loading = false;
          this.getSpares();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_changed"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_unchanged"),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
